export const environment = {
  production: false,

  // host: 'http://office-backend.vac.az/v1',
  // host: 'https://api.oneof.az/v1',



  // host: 'http://api-mg.timetower.az/v1',
    host: 'https://dev-api.oneof.az/v1',
    hostApi: 'https://dev-api.oneof.az/api/v1',
    hostClient: 'https://api-admin.oneof.az',
    // hostClient: 'https://admin.oneof.az',
   // host: 'https://new-dev-api.oneof.az/v1',


  // host: 'http://demo.api.oneof.az/v1',
  // site_url: 'http://test.oneof.az/',
  site_url: 'http://1of.az/',
  // site_url: 'http://demo.oneof.az/',
  client_secret: 'FaxAvX3mdFeES2UEF55nFMko3nqZQnpJhR7oE9FS',
};

